import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Voice Actions: add option to team and skill *`}</li>
      <li parentName="ul">{`Voice Actions: add column to listing panels *`}</li>
      <li parentName="ul">{`Workflows: Control of ContactEnded events when using multiple workflows for the same contact.`}</li>
      <li parentName="ul">{`Engagement Studio: Deprecated widgets and apps that were not being used.`}</li>
      <li parentName="ul">{`Engagement Cloud: URL shortened and updated from Byside to Coremedia.`}</li>
      <li parentName="ul">{`Bug Fix: Error creating exports and old exports blocked on Progress.`}</li>
      <li parentName="ul">{`Bug Fix: Error generating report from report App.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      